import React from 'react';
import { Flex} from 'rebass'

const ExampleList = ({data}) => (
    <Flex flexWrap='wrap' justifyContent='space-between'>
        {
            data.map((tutorial, index) => {
                return (
                    <Flex key={index} width={[0.45, 0.31]}>
                        <Flex flexDirection='column'>
                            <a href={tutorial.link} style={{color: 'inherit', textDecoration: "none", marginTop: "6px"}}>
                            <h4 style={{lineHeight: '1.5'}}>{tutorial.title}</h4></a>
                        </Flex>
                    </Flex>
                )
            })
        }
    </Flex>
);

export default ExampleList;