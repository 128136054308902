export default {
    boto3: [
        {
            title: "Get All Items/Scan",
            link: "https://dynobase.dev/dynamodb-python-with-boto3/#scan"
        },
        {
            title: "Get Item",
            link: "https://dynobase.dev/dynamodb-python-with-boto3/#get-item"
        },
        {
            title: "Batch Get Item",
            link: "https://dynobase.dev/dynamodb-python-with-boto3/#batch-get-item"
        },
        {
            title: "Put Item",
            link: "https://dynobase.dev/dynamodb-python-with-boto3/#put-item"
        },
        {
            title: "Query Set of Items",
            link: "https://dynobase.dev/dynamodb-python-with-boto3/#query-items"
        },
        {
            title: "Update Item",
            link: "https://dynobase.dev/dynamodb-python-with-boto3/#update-item"
        },
        {
            title: "Conditionally Update Item",
            link: "https://dynobase.dev/dynamodb-python-with-boto3/#conditionally-update-item"
        },
        {
            title: "Increment Item Attribute",
            link: "https://dynobase.dev/dynamodb-python-with-boto3/#increment-item-attribute"
        },
        {
            title: "Delete Item",
            link: "https://dynobase.dev/dynamodb-python-with-boto3/#delete-item"
        },
        {
            title: "Delete All Items",
            link: "https://dynobase.dev/dynamodb-python-with-boto3/#delete-all-items"
        },
        {
            title: "Query with Sorting",
            link: "https://dynobase.dev/dynamodb-python-with-boto3/#query-with-sorting"
        },

        {
            title: "Query Pagination",
            link: "https://dynobase.dev/dynamodb-python-with-boto3/#query-pagination"
        },
    ],
    node: [
        {
            title: "Get All Items/Scan",
            link: "https://dynobase.dev/dynamodb-nodejs/#scan"
        },
        {
            title: "Get Item",
            link: "https://dynobase.dev/dynamodb-nodejs/#get-item"
        },
        {
            title: "Batch Get Item",
            link: "https://dynobase.dev/dynamodb-nodejs/#batch-get-item"
        },
        {
            title: "Put Item",
            link: "https://dynobase.dev/dynamodb-nodejs/#put-item"
        },
        {
            title: "Query Set of Items",
            link: "https://dynobase.dev/dynamodb-nodejs/#query-items"
        },
        {
            title: "Update Item",
            link: "https://dynobase.dev/dynamodb-nodejs/#update-item"
        },
        {
            title: "Conditionally Update Item",
            link: "https://dynobase.dev/dynamodb-nodejs/#conditionally-update-item"
        },
        {
            title: "Increment Item Attribute",
            link: "https://dynobase.dev/dynamodb-nodejs/#increment-item-attribute"
        },
        {
            title: "Delete Item",
            link: "https://dynobase.dev/dynamodb-nodejs/#delete-item"
        },
        {
            title: "Delete All Items",
            link: "https://dynobase.dev/dynamodb-nodejs/#delete-all-items"
        },
        {
            title: "Query with Sorting",
            link: "https://dynobase.dev/dynamodb-nodejs/#query-with-sorting"
        },

        {
            title: "Query Pagination",
            link: "https://dynobase.dev/dynamodb-nodejs/#query-pagination"
        },
    ],
    cli: [
        {
            title: "Create Table",
            link: "https://dynobase.dev/dynamodb-cli-query-examples/#create-table"
        },
        {
            title: "Delete Table",
            link: "https://dynobase.dev/dynamodb-cli-query-examples/#delete-table"
        },
        {
            title: "Describe Table",
            link: "https://dynobase.dev/dynamodb-cli-query-examples/#describe-table"
        },
        {
            title: "Backup Table",
            link: "https://dynobase.dev/dynamodb-cli-query-examples/#backup-table"
        },
        {
            title: "Restore Table from Backup",
            link: "https://dynobase.dev/dynamodb-cli-query-examples/#restore-table"
        },
        {
            title: "Get All Items/Scan",
            link: "https://dynobase.dev/dynamodb-cli-query-examples/#scan"
        },
        {
            title: "Get Item",
            link: "https://dynobase.dev/dynamodb-cli-query-examples/#get-item"
        },
        {
            title: "Put Item",
            link: "https://dynobase.dev/dynamodb-cli-query-examples/#put-item"
        },
        {
            title: "Query Set of Items",
            link: "https://dynobase.dev/dynamodb-cli-query-examples/#query-items"
        },
        {
            title: "Update Item",
            link: "https://dynobase.dev/dynamodb-cli-query-examples/#update-item"
        },
        {
            title: "Increment Item Attribute",
            link: "https://dynobase.dev/dynamodb-cli-query-examples/#increment-item-attribute"
        },
        {
            title: "Delete Item",
            link: "https://dynobase.dev/dynamodb-cli-query-examples/#delete-item"
        },
        {
            title: "Query with Sorting",
            link: "https://dynobase.dev/dynamodb-cli-query-examples/#query-with-sorting"
        },
        {
            title: "Query Pagination",
            link: "https://dynobase.dev/dynamodb-cli-query-examples/#query-pagination"
        },
    ],
    golang: [
        {
            title: "Setup",
            link: "https://dynobase.dev/dynamodb-golang-query-examples/#setup"
        },
        {
            title: "Create Table",
            link: "https://dynobase.dev/dynamodb-golang-query-examples/#create-table"
        },
        {
            title: "Delete Table",
            link: "https://dynobase.dev/dynamodb-golang-query-examples/#delete-table"
        },
        {
            title: "List Tables",
            link: "https://dynobase.dev/dynamodb-golang-query-examples/#list-tables"
        },
        {
            title: "Scan",
            link: "https://dynobase.dev/dynamodb-golang-query-examples/#get-all-items"
        },
        {
            title: "Get Item",
            link: "https://dynobase.dev/dynamodb-golang-query-examples/#get-item"
        },
        {
            title: "Batch Get Item",
            link: "https://dynobase.dev/dynamodb-golang-query-examples/#batch-get-item"
        },
        {
            title: "Put Item",
            link: "https://dynobase.dev/dynamodb-golang-query-examples/#put-item"
        },
        {
            title: "Batch Write/Put Item",
            link: "https://dynobase.dev/dynamodb-golang-query-examples/#batch-write-item"
        },
        {
            title: "Query for a Set of Items",
            link: "https://dynobase.dev/dynamodb-golang-query-examples/#query"
        },
        {
            title: "Query an Index",
            link: "https://dynobase.dev/dynamodb-golang-query-examples/#query-index"
        },
        {
            title: "Transaction",
            link: "https://dynobase.dev/dynamodb-golang-query-examples/#transaction"
        },
        {
            title: "Read Transaction",
            link: "https://dynobase.dev/dynamodb-golang-query-examples/#read-transaction"
        },
        {
            title: "Query with Sorting",
            link: "https://dynobase.dev/dynamodb-golang-query-examples/#query-with-sorting"
        },
        {
            title: "Query (and Scan) DynamoDB Pagination",
            link: "https://dynobase.dev/dynamodb-golang-query-examples/#pagination"
        },
        {
            title: "Update Item",
            link: "https://dynobase.dev/dynamodb-golang-query-examples/#update-item"
        },
        {
            title: "Update Item",
            link: "https://dynobase.dev/dynamodb-golang-query-examples/#update-item-conditionally"
        },
        {
            title: "Increment Item Attribute",
            link: "https://dynobase.dev/dynamodb-golang-query-examples/#increment-item-attribute"
        },
        {
            title: "Delete Item",
            link: "https://dynobase.dev/dynamodb-golang-query-examples/#delete-item"
        },
        {
            title: "Delete All Items",
            link: "https://dynobase.dev/dynamodb-golang-query-examples/#delete-all-items"
        },
        {
            title: "Run DynamoDB Local",
            link: "https://dynobase.dev/dynamodb-golang-query-examples/#dynamodb-local"
        }
    ],
    java: [
        {
            title: "Setup",
            link: "https://dynobase.dev/dynamodb-java-with-dynamodbmapper/#setup"
        },
        {
            title: "Create Table",
            link: "https://dynobase.dev/dynamodb-java-with-dynamodbmapper/#create-table"
        },
        {
            title: "Connection",
            link: "https://dynobase.dev/dynamodb-java-with-dynamodbmapper/#connection"
        },
        {
            title: "Put Item",
            link: "https://dynobase.dev/dynamodb-java-with-dynamodbmapper/#put-item"
        },
        {
            title: "Get Item",
            link: "https://dynobase.dev/dynamodb-java-with-dynamodbmapper/#get-item"
        },
        {
            title: "Get Item",
            link: "https://dynobase.dev/dynamodb-java-with-dynamodbmapper/#get-item"
        },
        {
            title: "Update Item",
            link: "https://dynobase.dev/dynamodb-java-with-dynamodbmapper/#update-item"
        },
        {
            title: "Batch Put Item",
            link: "https://dynobase.dev/dynamodb-java-with-dynamodbmapper/#batch-put-item"
        },
        {
            title: "Batch Put Item & Delete",
            link: "https://dynobase.dev/dynamodb-java-with-dynamodbmapper/#batch-put-item-and-delete"
        },
        {
            title: "Batch Get Items",
            link: "https://dynobase.dev/dynamodb-java-with-dynamodbmapper/#batch-get-items"
        },
        {
            title: "Scan With Filter",
            link: "https://dynobase.dev/dynamodb-java-with-dynamodbmapper/#scan-with-filter"
        },
        {
            title: "Scan Parallel",
            link: "https://dynobase.dev/dynamodb-java-with-dynamodbmapper/#scan-parallel"
        },
        {
            title: "Query",
            link: "https://dynobase.dev/dynamodb-java-with-dynamodbmapper/#query"
        },
        {
            title: "Delete Item",
            link: "https://dynobase.dev/dynamodb-java-with-dynamodbmapper/#delete-item"
        },
        {
            title: "Batch Delete Items",
            link: "https://dynobase.dev/dynamodb-java-with-dynamodbmapper/#batch-delete-items"
        },
        {
            title: "Delete Table",
            link: "https://dynobase.dev/dynamodb-java-with-dynamodbmapper/#delete-table"
        },
        {
            title: "Run DynamoDB Local",
            link: "https://dynobase.dev/dynamodb-java-with-dynamodbmapper/#dynamodb-local"
        },
    ],
    csharp: [
        {
            title: "Setup",
            link: "https://dynobase.dev/dynamodb-csharp-dotnet/#setup"
        },
        {
            title: "Create Table",
            link: "https://dynobase.dev/dynamodb-csharp-dotnet/#create-table"
        },
        {
            title: "Query",
            link: "https://dynobase.dev/dynamodb-csharp-dotnet/#query"
        },
        {
            title: "Delete Table",
            link: "https://dynobase.dev/dynamodb-csharp-dotnet/#delete-table"
        },
        {
            title: "Delete Item",
            link: "https://dynobase.dev/dynamodb-csharp-dotnet/#delete-item"
        },
        {
            title: "Scan",
            link: "https://dynobase.dev/dynamodb-csharp-dotnet/#scan"
        },
        {
            title: "Get Items Using Query Filter",
            link: "https://dynobase.dev/dynamodb-csharp-dotnet/#get-items-using-query-filter"
        },
        {
            title: "Put Item",
            link: "https://dynobase.dev/dynamodb-csharp-dotnet/#put-item"
        },
        {
            title: "Get All Items",
            link: "https://dynobase.dev/dynamodb-csharp-dotnet/#get-all-items"
        },
        {
            title: "Get All Items (with Pagination)",
            link: "https://dynobase.dev/dynamodb-csharp-dotnet/#get-all-items-with-pagination)"
        },
        {
            title: "Get Single Item",
            link: "https://dynobase.dev/dynamodb-csharp-dotnet/#get-item"
        },
        {
            title: "Update Item",
            link: "https://dynobase.dev/dynamodb-csharp-dotnet/#update-item"
        },
        {
            title: "Batch Get Items",
            link: "https://dynobase.dev/dynamodb-csharp-dotnet/#batch-get-items"
        },
        {
            title: "Batch Write Items",
            link: "https://dynobase.dev/dynamodb-csharp-dotnet/#batch-write-items"
        },
        {
            title: "Run Transactions",
            link: "https://dynobase.dev/dynamodb-csharp-dotnet/#transactions"
        },
        {
            title: "Run DynamoDB Local",
            link: "https://dynobase.dev/dynamodb-csharp-dotnet/#dynamodb-local"
        }
    ],
}