import React from 'react';
import { Container } from '@components'
import { Box } from 'rebass'
import data from './Data'
import ExampleList from './ExampleList'
import Headline from '@components/aggregation/Headline.js'

const Content = () => (
       <Container>
           <Box mt={[32, 62]}>
                <Headline headline={'Boto3 (Python)'} id='boto3'/>
                <ExampleList data={data.boto3} />

                <Headline headline={'DocumentClient (Node.js)'} id='node'/>
                <ExampleList data={data.node} />

                <Headline headline={'AWS CLI (DynamoDB)'} id='cli'/>
                <ExampleList data={data.cli} />

                <Headline headline={'AWS Go SDK (Golang)'} id='golang'/>
                <ExampleList data={data.golang} />

                <Headline headline={'DynamoDBMapper (Java)'} id='java'/>
                <ExampleList data={data.java} />

                <Headline headline={'AWS .NET SDK (C#)'} id='csharp'/>
                <ExampleList data={data.csharp} />
          </Box>
       </Container>     
    );

export default Content;